import { createI18n, I18n, PluralizationRule } from 'vue-i18n';

/**
 * Set up global i18n instance for use with Vue
 * https://vue-i18n.intlify.dev/guide/advanced/typescript
 */

// Function to load locale messages dynamically
async function loadLocaleMessages(locale: string) {
  try {
    let messages;
    switch (locale) {
      case 'da':
        messages = await import('@/locales/da.json');
        break;

      case 'de':
        messages = await import('@/locales/de.json');
        break;

      case 'en':
        messages = await import('@/locales/en.json');
        break;

      case 'es':
        messages = await import('@/locales/es.json');
        break;

      case 'fi':
        messages = await import('@/locales/fi.json');
        break;

      case 'fr':
        messages = await import('@/locales/fr.json');
        break;

      case 'it':
        messages = await import('@/locales/it.json');
        break;

      case 'nl':
        messages = await import('@/locales/nl.json');
        break;

      case 'no':
        messages = await import('@/locales/no.json');
        break;

      case 'pl':
        messages = await import('@/locales/pl.json');
        break;

      case 'se':
        messages = await import('@/locales/se.json');
        break;

      default:
        messages = await import('@/locales/en.json');
        console.error(
          `No locale "${locale}" is defined in project, fallback to "en"`
        );
    }

    return messages.default || messages;
  } catch (error) {
    console.error(`Failed to load locale messages for ${locale}`, error);
    throw error;
  }
}

export type MessageSchema = Awaited<ReturnType<typeof loadLocaleMessages>>;

let i18nInstance: I18n<MessageSchema> | null = null;

export async function setupI18n(locale: string) {
  if (!i18nInstance) {
    const messages = await loadLocaleMessages(locale);

    /**
     * @param choice the number to determine the plural form
     * @param choicesLength the number of different plural forms provided in the translation message
     */
    const polishRules: PluralizationRule = (choice, choicesLength) => {
      // Handle choices length of 1 (only singular)
      if (choicesLength === 1) {
        console.warn(
          'Expected 3 plural forms for Polish, but only 1 form is provided. This may not produce correct grammar.'
        );
        // If only one form is provided, always use the first form
        return 0;
      }

      // Singular form: used when the number is exactly 1
      if (choice === 1) {
        // Return the first form for singular
        return 0;
      }

      // Handle choices length of 2 (singular and plural)
      if (choicesLength === 2) {
        console.warn(
          'Expected 3 plural forms for Polish, but only 2 forms are provided. This may not produce correct grammar.'
        );
        // If only two forms are provided, use the second form for all other numbers
        return 1;
      }

      // Plural form 1: used for numbers ending in 2, 3, or 4, except those ending in 12, 13, or 14
      if (
        choice % 10 >= 2 &&
        choice % 10 <= 4 &&
        (choice % 100 < 12 || choice % 100 > 14)
      ) {
        // Return the second form for numbers ending in 2-4, excluding 12-14
        return 1;
      }

      // Plural form 2: used for all other numbers
      if (choicesLength < 3) {
        console.warn(
          'Expected 3 plural forms for Polish, but less than 3 forms are provided. This may not produce correct grammar.'
        );
        // If fewer than 3 forms are provided, default to the second form
        return 1;
      }

      // Return the third form for all other numbers
      return 2;
    };

    i18nInstance = createI18n({
      legacy: false, // Ensure you set this to false for Vue 3
      locale,
      pluralRules: {
        pl: polishRules
      },
      fallbackLocale: 'en',
      globalInjection: false, // Do not expose $t() etc, forcing composition API
      messages: {
        // Only setup a single locale. We currently only support one language per market, so no need to expose multiple locales
        [locale]: messages
      }
    });
  }

  return i18nInstance;
}
